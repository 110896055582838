import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-view-lists',
  templateUrl: './view-lists.component.html',
  styleUrls: ['./view-lists.component.scss']
})
export class ViewListsComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
