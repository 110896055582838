import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';
import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { Router, ActivatedRoute } from '@angular/router';

import { LoginRequest } from './login-request';
import { DataSharingService } from './data-sharing.service';
import { MessageService } from './message.service';
import { AuthResult } from './auth-result';

@Injectable({
  providedIn: 'root'
})
export class AuthenticationService {

  //testUsername: string = "username";
  //testPassword: string = "password";
  	httpOptions = {
	  headers: new HttpHeaders({ 'Content-Type': 'application/json' })
	};
  INITIAL_PATH: string = "/";
  constructor(	private http: HttpClient,
				private messageService: MessageService,
				private dataSharingService: DataSharingService,
				private route: ActivatedRoute,
				private router: Router) { }
  
  login(request : LoginRequest):void {

	  this.getLoginResult(request).subscribe((result) => this.LoginResult(result));

  }
  
  logout(): void {
	localStorage.removeItem("token");
	localStorage.removeItem("username");
	localStorage.removeItem("firstname");
	localStorage.removeItem("lastname");
	localStorage.removeItem("email");
	localStorage.removeItem("personid");
	this.dataSharingService.isUserLoggedIn.next(false);
	this.dataSharingService.token.next("");
	this.dataSharingService.username.next("");
	this.dataSharingService.firstname.next("");
	this.dataSharingService.lastname.next("");
	this.dataSharingService.email.next("");
	this.dataSharingService.personid.next("");
  }
  
  isUserLoggedIn(): boolean {
	  if(localStorage.getItem("token") != null) {
		  return true;
	  }
	  return false;
  }
  
  getLoginResult(request : LoginRequest) : Observable<AuthResult> { 
		const url = `https://fellowshipbakingday.com/user_management.php?method=login`;
		return this.http.post<AuthResult>(url,request, this.httpOptions).pipe(
			tap(_ => this.log(`logging in as user_id=${request.username}`)),
			catchError(this.handleError<AuthResult>('getLoginResult')));
  }
  getProfile() : AuthResult {
	  var authResult : AuthResult = new AuthResult();
	  authResult.token = this.dataSharingService.token.value;
	  authResult.username = this.dataSharingService.username.value;
	  authResult.firstName = this.dataSharingService.firstname.value;
	  authResult.lastName = this.dataSharingService.lastname.value;
	  authResult.email = this.dataSharingService.email.value;
	  return authResult;
  }
  updateProfile(authResult : AuthResult) : Observable<AuthResult>{
	  		return this.http.post<AuthResult>("https://fellowshipbakingday.com/user_management.php?method=update", authResult, this.httpOptions).pipe(
			tap(_ => this.log(`updated username=${authResult.username}`)),
			catchError(this.handleError<AuthResult>('updateProfile')));
  }
  updatePassword(pass : string, token : string) : void{
	  		this.http.post<boolean>("https://fellowshipbakingday.com/user_management.php?method=setpassword", {token,pass}, this.httpOptions).pipe(
			tap(_ => this.log(`updated account`)),
			catchError(this.handleError<boolean>('updateProfile'))).subscribe((result) => this.updateSuccess(result));
  }
  private updateSuccess(success : boolean){
		if(success){
			this.router.navigate([this.INITIAL_PATH]);
		}
		else {
			alert(success);
		}
  }
  private LoginResult(authResult : AuthResult){
	  //console.log(authResult);
	  //alert(JSON.stringify(authResult));
	  if(authResult != null) {
		 localStorage.setItem("token",authResult.token);
 		 localStorage.setItem("username",authResult.username);
		 localStorage.setItem("firstname",authResult.firstName);
		 localStorage.setItem("lastname",authResult.lastName);
		 localStorage.setItem("email",authResult.email);
		 localStorage.setItem("personid",authResult.personid);
		 //alert(this.dataSharingService);
		 this.dataSharingService.isUserLoggedIn.next(true);
		 this.dataSharingService.token.next(authResult.token);
		 this.dataSharingService.username.next(authResult.username);
		 this.dataSharingService.firstname.next(authResult.firstName);
		 this.dataSharingService.lastname.next(authResult.lastName);
		 this.dataSharingService.email.next(authResult.email);
		 this.dataSharingService.personid.next(authResult.personid);
		 this.router.navigate([this.INITIAL_PATH]);
		 //alert("logged in");
		 //return of(new HttpResponse({status: 200}));
	  }
	  else {
		  alert("Invalid Password");
	  }
  }
  private wait(ms){
	   var start = new Date().getTime();
	   var end = start;
	   while(end < start + ms) {
		 end = new Date().getTime();
  }
}
  	private log(message: string) {
		this.messageService.add(`AuthenticationService: ${message}`);
	}
	
	  /**
 	* Handle Http operation that failed.
 	* Let the app continue.
 	* @param operation - name of the operation that failed
 	* @param result - optional value to return as the observable result
 	*/
	private handleError<T> (operation = 'operation', result?: T) {
	  return (error: any): Observable<T> => {

	    // TODO: send the error to remote logging infrastructure
	    console.error(error); // log to console instead

	    // TODO: better job of transforming error for user consumption
	    this.log(`${operation} failed: ${error.message}`);

	    // Let the app keep running by returning an empty result.
	    return of(result as T);
	  };
	}
	
}
