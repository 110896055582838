import { Component, OnInit, Input } from '@angular/core';
import { Person } from '../person';
import { ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';
import { Answer } from '../answer';

import { PersonService } from '../person.service';
import { DataSharingService } from '../data-sharing.service';
@Component({
  selector: 'app-person-detail',
  templateUrl: './person-detail.component.html',
  styleUrls: ['./person-detail.component.scss']
})
export class PersonDetailComponent implements OnInit {

@Input() person: Person;
@Input() answers: Answer[];
@Input() giftee: Person;
@Input() giftee_display;
  constructor(
  	private route: ActivatedRoute,
  	private personService: PersonService,
  	private location: Location,
	private dataSharingService : DataSharingService
  ) { }

  ngOnInit() {
	this.route.params.subscribe(
        params => {
            //const id = +params['id'];
			this.giftee_display = false;
			this.getPerson();
			this.getPersonDetails();
			this.getGiftee();
        }
    );

  }
  
  getPerson(): void {
		const id = +this.route.snapshot.paramMap.get('id');
		const year = +this.route.snapshot.queryParamMap.get('year');
		this.personService.getPerson(id,year).subscribe(person => this.person = person);
		
  }
  
  getPersonDetails(): void {
		const id = +this.route.snapshot.paramMap.get('id');
		const year = +this.route.snapshot.queryParamMap.get('year');
		this.personService.getPersonDetails(id,year).subscribe(answers => this.answers = answers);  
  }
  getGiftee(): void {
		this.giftee = null;
		const id = +this.route.snapshot.paramMap.get('id');
		if(id.toString() == this.dataSharingService.personid.value){
			var token = this.dataSharingService.token.value;
			this.personService.getGifteeDetails(id,token).subscribe(person => this.giftee = person);  	
		}
  }

    
  goBack(): void {
  this.location.back();
}

	save(): void {
		const year = +this.route.snapshot.queryParamMap.get('year');
		var token = this.dataSharingService.token.value;
		this.personService.updateWholePerson(year, this.person, token, this.answers)
	      .subscribe(results => results);
	 }
}
