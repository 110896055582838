import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class DataSharingService {
	public isUserLoggedIn: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
	public username : BehaviorSubject<string> = new BehaviorSubject<string>('');
	public token : BehaviorSubject<string> = new BehaviorSubject<string>('');
	public firstname : BehaviorSubject<string> = new BehaviorSubject<string>('');
	public lastname : BehaviorSubject<string> = new BehaviorSubject<string>('');
	public email : BehaviorSubject<string> = new BehaviorSubject<string>('');
	public personid : BehaviorSubject<string> = new BehaviorSubject<string>('');
  constructor() 
  { 
	this.isUserLoggedIn.next( (localStorage.getItem("token") != "" &&	localStorage.getItem("token") != null));
	this.token.next(localStorage.getItem("token"));
	this.username.next(localStorage.getItem("username"));
	this.firstname.next(localStorage.getItem("firstname"));
	this.lastname.next(localStorage.getItem("lastname"));
	this.email.next(localStorage.getItem("email"));
	this.personid.next(localStorage.getItem("personid"));
	
  }
}
