import { Component } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { AuthenticationService } from './authentication.service';
import { DataSharingService } from './data-sharing.service';
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'Baking Day';
  //auth :  AuthenticationService = new AuthenticationService(this.dataSharingService);
  //private router: Router;
  loggedin : boolean;
  username : string;
  
  constructor(private dataSharingService: DataSharingService, private auth : AuthenticationService, private router : Router){
	  this.loggedin = false;
	  this.dataSharingService.isUserLoggedIn.subscribe( value => {
            this.loggedin = value;
        });
	  this.dataSharingService.username.subscribe( value => {
            this.username = value;
			//alert(value);
        });
  }
  logOut(){
	  this.auth.logout();
	  this.router.navigate([this.auth.INITIAL_PATH]);
  }
}
