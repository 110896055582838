import { Component, OnInit } from '@angular/core';
import { FormsModule, FormBuilder, FormGroup, Validators, ReactiveFormsModule } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { LoginRequest } from '../login-request';
import { DataSharingService } from "../data-sharing.service";

import { AuthenticationService } from '../authentication.service';
import { AuthResult } from '../auth-result';

@Component({
  selector: 'app-auth',
  templateUrl: './auth.component.html',
  styleUrls: ['./auth.component.scss']
})
export class AuthComponent implements OnInit {
  loginForm: FormGroup;
  loading = false;
  submitted = false;
  returnUrl: string; 

  constructor(  
		private formBuilder: FormBuilder,
        private route: ActivatedRoute,
        private router: Router,
        private authenticationService: AuthenticationService,
		private dataSharingService : DataSharingService) { 	}

  ngOnInit(): void {
	  this.loginForm = this.formBuilder.group({
		  username: ['',Validators.email],
		  password: ['']	  
	  });
	  
  }

  get f() { return this.loginForm.controls; }
  onSubmit() {
	  this.login();
  }
  login() {
	  const loginRequest: LoginRequest = {
		  username: this.f.username.value,
		  password: this.f.password.value
	  };
	  var authResult : AuthResult;
	  //const cDataSharingService : DataSharingService = this.dataSharingService;
	  this.authenticationService.login(loginRequest);

	  //.subscribe((user) => this.router.navigate([this.authenticationService.INITIAL_PATH]));
  }

}
