import { Component, OnInit, Input } from '@angular/core';
import { Person } from '../person';
import { ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';
import { Answer } from '../answer';

import { PersonService } from '../person.service';
import { DataSharingService } from '../data-sharing.service';

@Component({
  selector: 'app-person-detail-edit',
  templateUrl: './person-detail-edit.component.html',
  styleUrls: ['./person-detail-edit.component.scss']
})
export class PersonDetailEditComponent implements OnInit {


@Input() person: Person;
@Input() answers: Answer[];
  constructor(
  	private route: ActivatedRoute,
  	private personService: PersonService,
  	private location: Location,
	private dataSharingService: DataSharingService
  ) { }

  ngOnInit() {
  	this.getPerson();
  	this.getPersonDetails();
  }
  
  getPerson(): void {
		const id = +this.route.snapshot.paramMap.get('id');
		const year = +this.route.snapshot.queryParamMap.get('year');
		this.personService.getPerson(id,year).subscribe(person => this.person = person);
		
  }
  
  getPersonDetails(): void {
		const id = +this.route.snapshot.paramMap.get('id');
		const year = +this.route.snapshot.queryParamMap.get('year');
		this.personService.getPersonDetails(id,year).subscribe(answers => this.answers = answers);  
  }
  
  goBack(): void {
  this.location.back();
}

	save(): void {
		const year = +this.route.snapshot.queryParamMap.get('year');
		var token = this.dataSharingService.token.value;
		this.personService.updateWholePerson(year, this.person, token, this.answers)
			.subscribe(results => results);
	 }
}
