import { Component, OnInit } from '@angular/core';
import { Person } from '../person';
import { ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';

import { PersonService } from '../person.service';

@Component({
  selector: 'app-people',
  templateUrl: './people.component.html',
  styleUrls: ['./people.component.scss']
})
export class PeopleComponent implements OnInit {
	
	people: Person[];
		
  constructor(private route: ActivatedRoute, private personService: PersonService) { }

  ngOnInit() {
  	this.getPeople();
  }


  getPeople(): void {
	const year = +this.route.snapshot.queryParamMap.get('year');
  	this.personService.getPeople(year)
  		.subscribe(people => this.people = people);
  }  
  
  add(firstName: string, lastName: string): void {
  firstName = firstName.trim();
  lastName = lastName.trim();
  if (!firstName && !lastName) { return; }
  this.personService.addPerson({ firstName, lastName } as Person)
    .subscribe(person => {
      this.people.push(person);
    });
	}
	delete(person: Person): void {
	  this.people = this.people.filter(p => p !== person);
	  this.personService.deletePerson(person).subscribe();
	}
  
}
