import { Component, OnInit, Input } from '@angular/core';

import { AuthenticationService } from '../authentication.service';
import { AuthResult } from '../auth-result';
@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss']
})
export class ProfileComponent implements OnInit {

@Input() authResult: AuthResult;
  constructor(  	
    private authService: AuthenticationService) { }

  ngOnInit(): void {
	this.GetProfile();
  }
  GetProfile(): void {	  
	this.authResult = this.authService.getProfile();
  }
  private update(authResult : AuthResult): void {
	this.authService.updateProfile(authResult)
			.subscribe(results => results);
  }

}
