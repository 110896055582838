import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { Observable, of } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';

import { Person } from './person';
import { Question } from './question';
import { Answer } from './answer';
import { PEOPLE } from './mock-people';
import { MessageService } from './message.service';
import { UserPeople } from './user-people';

@Injectable({
  providedIn: 'root'
})
export class PersonService {

	httpOptions = {
	  headers: new HttpHeaders({ 'Content-Type': 'application/json' })
	};

	private peopleUrl = 'api/people';  // URL to web api

	constructor(
		private http: HttpClient,
		private messageService: MessageService) { }

	/* getPeople() : Observable<Person[]> {
		return this.http.get<Person[]>(this.peopleUrl)
			.pipe(
			  tap(_ => this.log('fetched people')),
				catchError(this.handleError<Person[]>('getPeople', [] ))
			);
	} */
	getPeople(year: number) : Observable<Person[]> {
		return this.http.get<Person[]>(`https://fellowshipbakingday.com/update-database.php?year=${year}`)
			.pipe(
			  tap(_ => this.log(`fetched people with year=${year}`)),
				catchError(this.handleError<Person[]>('getPeople', [] ))
			);
	}
	
	getPerson(id: number, year: number): Observable<Person> {
		const url = `https://fellowshipbakingday.com/update-database.php?id=${id}&year=${year}`;
		return this.http.get<Person>(url).pipe(
			tap(_ => this.log(`fetched person id=${id} and year=${year}`)),
			catchError(this.handleError<Person>(`getPerson id=${id}`))
		);
	}

	/** POST: update the person on the server */
	updatePerson (person: Person): Observable<any> {
		return this.http.post<Person>("https://fellowshipbakingday.com/update-database.php", person, this.httpOptions).pipe(
			tap(_ => this.log(`updated person id=${person.id}`)),
			catchError(this.handleError<any>('updatePerson'))
  	);
}
	private log(message: string) {
		this.messageService.add(`PersonService: ${message}`);
	}
	
		/** POST: update the person on the server */
	updateWholePerson (year: number, person: Person, token : string, answers:Answer[]): Observable<any> {
		var wholePerson = {person: person, answers:answers , year:year};
		return this.http.post<any>(`https://fellowshipbakingday.com/get-person-details.php?token=${token}`, wholePerson, this.httpOptions).pipe(
			tap(_ => this.log(`updated person id=${person.id}`)),
			catchError(this.handleError<any>('updatePerson'))
  	);
}

	
	
	/** PUT: add a new person to the server */
	addPerson (person: Person): Observable<Person> {
	  return this.http.put<Person>("https://fellowshipbakingday.com/update-database.php", person, this.httpOptions).pipe(
	    tap((newPerson: Person) => this.log(`added person w/ id=${newPerson.id}`)),
	    catchError(this.handleError<Person>('addPerson'))
	  );
	}

	/** DELETE: delete the person from the server */
	deletePerson (person: Person | number): Observable<Person> {
	  const id = typeof person === 'number' ? person : person.id;
	  const url = `https://fellowshipbakingday.com/update-database.php?iq=${id}`;

	  return this.http.delete<Person>(url, this.httpOptions).pipe(
	    tap(_ => this.log(`deleted person id=${id} via deletePerson`)),
	    catchError(this.handleError<Person>('deletePerson'))
	  );
	}

	
	/* GET people whose name contains search term */
	searchPeople(term: string): Observable<Person[]> {
	  if (!term.trim()) {
	    // if not search term, return empty person array.
	    return of([]);
	  }
	  return this.http.get<Person[]>(`${this.peopleUrl}/?firsName=${term}`).pipe(
	    tap(_ => this.log(`found people matching "${term}"`)),
	    catchError(this.handleError<Person[]>('searchPeople', []))
	  );
	}
	
	getPersonDetails(person: Person | number, year: number):Observable<Answer[]> {
		const id = typeof person === 'number' ? person : person.id;
		return this.http.get<Answer[]>(`https://fellowshipbakingday.com/get-person-details.php?id=${id}&year=${year}`, this.httpOptions).pipe(
			tap(_ => this.log(`fetched details for person id=${id} and year=${year}`)),
			catchError(this.handleError<Answer[]>('getPersonDetails'))
	  );
	}
	
	getAllUserPeople(year : number, token : string):Observable<UserPeople[]> {
		return this.http.get<UserPeople[]>(`https://fellowshipbakingday.com/gift_management.php?method=get_match&token=${token}`, this.httpOptions).pipe(
			tap(_ => this.log(`fetched details for the year=${year}}`)),
			catchError(this.handleError<UserPeople[]>('getPersonDetails'))
		);
	}
	
	getGifteeDetails(id: number, token : string):Observable<Person> {
		return this.http.get<Person>(`https://fellowshipbakingday.com/gift_management.php?method=get_match&token=${token}&person_id=${id}`, this.httpOptions).pipe(
			tap(_ => this.log(`fetched details for the giftee for person id=${id}}`)),
			catchError(this.handleError<Person>('getPersonDetails'))
	  );
	}
	
	/*** Profile Management ***/
	getProfileDetailsByToken(token : string){
		
	}
  /**
 	* Handle Http operation that failed.
 	* Let the app continue.
 	* @param operation - name of the operation that failed
 	* @param result - optional value to return as the observable result
 	*/
	private handleError<T> (operation = 'operation', result?: T) {
	  return (error: any): Observable<T> => {

	    // TODO: send the error to remote logging infrastructure
	    console.error(error); // log to console instead

	    // TODO: better job of transforming error for user consumption
	    this.log(`${operation} failed: ${error.message}`);

	    // Let the app keep running by returning an empty result.
	    return of(result as T);
	  };
	}

}
