import { Component, OnInit } from '@angular/core';
import { Person } from '../person';
import { ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';

import { PersonService } from '../person.service';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: [ './dashboard.component.scss' ]
})
export class DashboardComponent implements OnInit {
  people: Person[] = [];

  constructor(private route: ActivatedRoute, private personService: PersonService) { }

  ngOnInit() {
    this.getPeople();
  }

  getPeople(): void {
   	const year = +this.route.snapshot.queryParamMap.get('year');
    this.personService.getPeople(year)
      .subscribe(people => this.people = people.slice(0, 5));
  }
}