import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { PeopleComponent } from './people/people.component';
import { DashboardComponent }   from './dashboard/dashboard.component';
import { PersonDetailComponent }  from './person-detail/person-detail.component';
import { PersonDetailEditComponent }  from './person-detail-edit/person-detail-edit.component';
import { PeopleEditComponent }  from './people-edit/people-edit.component';
import { AuthComponent }  from './auth/auth.component';
import { ProfileComponent }  from './profile/profile.component';
import { AdminPortalComponent } from './admin-portal/admin-portal.component';
import { ResetPasswordComponent } from './reset-password/reset-password.component';

const routes: Routes = [
{ path: '', redirectTo: '/people', pathMatch: 'full' },
{ path: 'detail/:id', component: PersonDetailComponent },
{ path: 'dashboard', component: DashboardComponent },
{ path: "people" , component: PeopleComponent },
{ path: 'peopleedit', component: PeopleEditComponent },
{ path: 'detailedit/:id', component: PersonDetailEditComponent },
{ path: 'auth', component: AuthComponent },
{ path: 'profile', component: ProfileComponent },
{ path: 'adminportal', component: AdminPortalComponent },
{ path: "resetpassword", component: ResetPasswordComponent }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
