import { InMemoryDbService } from 'angular-in-memory-web-api';
import { Person } from './person';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class InMemoryDataService implements InMemoryDbService {
  createDb() {
    const people = [
		  { id: 11, firstName: 'Dr', lastName: 'Nice'},
		  { id: 12, firstName: 'Narco', lastName: ''},
		  { id: 13, firstName: 'Bombasto', lastName: ''},
		  { id: 14, firstName: 'Celeritas', lastName: ''},
		  { id: 15, firstName: 'Magneta', lastName: ''},
		  { id: 16, firstName: 'RubberMan', lastName: ''},
		  { id: 17, firstName: 'Dynama', lastName: ''},
		  { id: 18, firstName: 'Dr', lastName: 'IQ'},
		  { id: 19, firstName: 'Magma', lastName: ''},
		  { id: 20, firstName: 'Tornado', lastName: ''}
    ];
    return {people};
  }

  // Overrides the genId method to ensure that a hero always has an id.
  // If the heroes array is empty,
  // the method below returns the initial number (11).
  // if the heroes array is not empty, the method below returns the highest
  // hero id + 1.
  genId(people: Person[]): number {
    return people.length > 0 ? Math.max(...people.map(person => person.id)) + 1 : 11;
  }
}