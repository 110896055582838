import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';


import { HttpClientInMemoryWebApiModule } from 'angular-in-memory-web-api';
import { InMemoryDataService }  from './in-memory-data.service';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { ViewListsComponent } from './view-lists/view-lists.component';
import { PeopleComponent } from './people/people.component';
import { PersonDetailComponent } from './person-detail/person-detail.component';
import { MessagesComponent } from './messages/messages.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { PersonSearchComponent } from './person-search/person-search.component';
import { PeopleEditComponent } from './people-edit/people-edit.component';
import { PersonDetailEditComponent } from './person-detail-edit/person-detail-edit.component';
import { AuthComponent } from './auth/auth.component';
import { DataSharingService } from './data-sharing.service';
import { ProfileComponent } from './profile/profile.component';
import { AdminPortalComponent } from './admin-portal/admin-portal.component';
import { ResetPasswordComponent } from './reset-password/reset-password.component';

@NgModule({
  declarations: [
    AppComponent,
    ViewListsComponent,
    PeopleComponent,
    PersonDetailComponent,
    MessagesComponent,
    DashboardComponent,
    PersonSearchComponent,
    PeopleEditComponent,
    PersonDetailEditComponent,
    AuthComponent,
    ProfileComponent,
    AdminPortalComponent,
    ResetPasswordComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
	FormsModule,
	ReactiveFormsModule,
    HttpClientModule,
    HttpClientInMemoryWebApiModule.forRoot(
 			InMemoryDataService, { dataEncapsulation: false,   passThruUnknownUrl: true }
		)
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
