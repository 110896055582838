import { Component, OnInit } from '@angular/core';
import { FormsModule, FormBuilder, FormGroup, Validators, ReactiveFormsModule } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';

import { AuthenticationService } from '../authentication.service';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss']
})
export class ResetPasswordComponent implements OnInit {
  passwordResetForm: FormGroup;
  resetToken : string;
  loading = false;
  constructor(
			private formBuilder: FormBuilder,
			private route: ActivatedRoute,
			private authService : AuthenticationService) { }

  ngOnInit(): void {
	    this.route.queryParams.subscribe(params => {
        this.resetToken = params['resetToken'];
    });
		  this.passwordResetForm = this.formBuilder.group({
		  pass: [''],
		  confirm: ['']	  
	  });
  }
  onSubmit() {
	  if(this.f.pass.value != "" && this.f.pass.value != null && this.f.confirm.value === this.f.pass.value){
		var success = this.resetPassword(this.f.pass.value, this.resetToken);
	  }
  }
  get f() { return this.passwordResetForm.controls; }
  resetPassword(pass : string, resetToken : string) : boolean{
	  //alert(pass);
	  //alert(resetToken);
	  this.authService.updatePassword(pass,resetToken);
	  return true;
  }
}
